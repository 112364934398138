import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class EventoBorradorService {

  // Observable refresh list data categorias
  flagRefresh = new BehaviorSubject<any>(null);
  observableData$ = this.flagRefresh.asObservable();

  constructor(private http: HttpClient) { }

  // actualizamos flag
  nextData(flag: boolean) {
    this.flagRefresh.next(flag);
  }

  obtenerEventosBorradorPorUsuario(usuarioId) {
    return this.http.get<any>(`${environment.api}/evento-borrador/usuario/${usuarioId}`);
  }

  obtenerEventoBorrador(id:number) {
    return this.http.get<any>(`${environment.api}/evento-borrador/${id}`);
  }

  guardarEventoBorrador(form: any) {
    return this.http.post<any>(`${environment.api}/evento-borrador`, form);
  }

  actualizarEventoBorrador(id: number, form: any) {
    return this.http.patch<any>(`${environment.api}/evento-borrador/${id}`, form);
  }




}
