import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EncryptService } from "../_shared/encrypt";

@Injectable()
export class Encryptnterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private encryptService: EncryptService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    req = req.clone({
      responseType: 'text',
      headers: req.headers.set('Accept', 'text/plain')
    });

    if (!req.url.includes('/crear-afiche')
      && !req.url.includes('/crear-banner')
      && !req.url.includes('/crear-comprobante')
      && !req.url.includes('/crear-foto-perfil')
      && !req.url.includes('/crear-comprobante-devolucion')
      && !req.url.includes('/crear-imagen-adicional')
      && !req.url.includes('/crear-cotizacion')
      && !req.url.includes('/crear-imagen')
      && !req.url.includes('/crear-geojson')
      && !req.url.includes('/archivo')

    ) {
      
      if(req.method === 'POST' || req.method === 'PATCH'){
          req = req.clone({
            body: this.encryptService.encryptData(req.body),
          });
      }
    }

   


    return next.handle(req).pipe(
      
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
            // Obtener la respuesta del servidor
            const response = event.body;

            // Modificar la respuesta según sea necesario
            const modifiedResponse = this.encryptService.decryptData(response);

            // Crear una nueva respuesta con los datos modificados
            const modifiedEvent = event.clone({ body: modifiedResponse });

            // Devolver la respuesta modificada
            return modifiedEvent;
        }
      })
    );
  }
}
