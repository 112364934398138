import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {PreviewComponent} from './preview.component';
import {ComponentsModule} from '../components.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [PreviewComponent],
  exports: [PreviewComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    NgbModule,
  ],
})
export class PreviewModule { }
