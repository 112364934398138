import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class DevolucionRiderService {

  // Observable refresh list data categorias
  flagRefresh = new BehaviorSubject<any>(null);
  observableData$ = this.flagRefresh.asObservable();

  constructor(private http: HttpClient) { }

  // actualizamos flag
  nextData(flag: boolean) {
    this.flagRefresh.next(flag);
  }

  obtenerDevolucionesPorEventoYEstado(eventoId: number, estado: string) {
    return this.http.get<any>(`${environment.api}/devolucion-rider/evento/${eventoId}/estado/${estado}`);
  }


  obtenerMontoDevolucion() {
    return this.http.get<any>(`${environment.api}/devolucion-rider/monto`);
  }


  obtenerCantidadDevolucion() {
    return this.http.get<any>(`${environment.api}/devolucion-rider/cantidad`);
  }



  obtenerDevolucionesConLimite(take: number) {
    return this.http.get<any>(`${environment.api}/devolucion-rider/take/${take}`);
  }


  crearDevolucion(form: any) {
    return this.http.post(`${environment.api}/devolucion-rider`, form);
  }

  actualizarDevolucion(devolucionId: number, form: any) {
    return this.http.patch(`${environment.api}/devolucion-rider/${devolucionId}`, form);
  }

  crearComprobanteDevolucion(riderId: number, file: any) {
    return this.http.post(`${environment.api}/devolucion-rider/rider/${riderId}/crear-comprobante-devolucion`, file);
  }

  obtenerDevolucion(id: number) {
    return this.http.get<any>(`${environment.api}/devolucion-rider/${id}`);
  }
}
