<div class="header-dots">

 <div class="btn-group" ngbDropdown autoClose="outside" placement="bottom" (openChange)="toggleButton($event)">
   <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle >
      <span>
        <div class="icon-wrapper icon-wrapper-alt rounded-circle" style="background-color: #d92550 !important;" *ngIf="notificationList.length > 0">
          <div class="icon-wrapper-bg"></div>
          <!-- <i class="lnr-alarm text-danger"></i> -->
          <i class="fa fa-exclamation" aria-hidden="true"></i>
          <!-- <div class="badge badge-dot badge-dot-sm badge-danger">Notificaciones</div> -->
        </div>

        <div class="icon-wrapper icon-wrapper-alt rounded-circle" style="background-color: #3ac47d !important;" *ngIf="notificationList.length === 0">
          <div class="icon-wrapper-bg bg-success"></div>
          <i class="fa fa-check" aria-hidden="true"></i>          
          <!-- <div class="badge badge-dot badge-dot-sm badge-danger">Notificaciones</div> -->
        </div>
      </span>
    </button>
    <div class="dropdown-menu-xl" ngbDropdownMenu>
      <ngx-loading [show]="loading"
                  [config]="{animationType: ngxLoadingAnimationTypes?.pulse, backdropBorderRadius: '3px'}"
                   [template]="loadingTemplate"></ngx-loading>
      <div class="dropdown-menu-header mb-0">
        <div class="dropdown-menu-header-inner bg-deep-blue">
          <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
          <div class="menu-header-content text-dark">
            <h5 class="menu-header-title">Notificaciones</h5>
          </div>
        </div>
      </div>
      <div *ngIf="!loading" class="card-tabbed-header">
        <ngb-tabset class="tabs-animated tabs-animated-shadow" justify="justified">

          <ngb-tab>
            <ng-template ngbTabTitle><span>Sol. cambio cat.</span></ng-template>
            <ng-template ngbTabContent>
              <div class="scroll-gradient" *ngIf="obtenerNotificacionPorTipo('SOLICITUD_CAMBIO_CATEGORIA').length > 0">
                <div class="scroll-area-sm shadow-overflow">
                  <perfect-scrollbar [autoPropagation]="true">
                    <div
                      class="vertical-without-time vertical-timeline vertical-timeline&#45;&#45;animate vertical-timeline&#45;&#45;one-column">
                      <div class="vertical-timeline-item vertical-timeline-element" *ngFor="let notification of obtenerNotificacionPorTipo('SOLICITUD_CAMBIO_CATEGORIA')">
                        <div><span class="vertical-timeline-element-icon bounce-in"><i
                              class="badge badge-dot badge-dot-xl badge-success"></i></span>
                          <div class="vertical-timeline-element-content bounce-vin">
                            <h4 class="timeline-title">{{notification.title}}</h4>
                            <p>{{notification.descripcion}}</p>
                            <div *ngIf = "notification.plataforma === 'BK'" class="badge badge-pill badge-primary">BIKERACE</div>
                              <div *ngIf = "notification.plataforma === 'RT'" class="badge badge-pill badge-primary">RIDERTICKET</div>
                            <p> 
                              {{notification.fecha | date : 'dd-MM-yyyy HH:mm' : 'ISO'}}</p>
                            <span
                              class="vertical-timeline-element-date"></span>

                              

                              <button (click)="clickTarea(notification)" type="button" class="btn-shadow d-inline-flex align-items-center btn btn-primary btn-transition btn-shadow btn-hover-shine">
                                <em class="fa fa-mouse-pointer" title="star" style="margin-right: 10px"></em>
                                Ir a solicitud
                              </button>

                          </div>
                        </div>
                      </div>

                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
              <div class="no-results"  *ngIf="obtenerNotificacionPorTipo('SOLICITUD_CAMBIO_CATEGORIA').length === 0">
                <div  class="swal2-icon swal2-success swal2-animate-success-icon">
                  <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                  <span class="swal2-success-line-tip"></span>
                  <span class="swal2-success-line-long"></span>
                  <div class="swal2-success-ring"></div>
                  <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                  <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                </div>


                <div class="results-subtitle">Perfecto!</div>
                <div  class="results-title">No tienes tareas pendientes por realizar!</div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><span>Sol. devolución</span></ng-template>
            <ng-template ngbTabContent>
              <div class="scroll-gradient" *ngIf="obtenerNotificacionPorTipo('SOLICITUD_DEVOLUCION').length > 0">
                <div class="scroll-area-sm shadow-overflow">
                  <perfect-scrollbar [autoPropagation]="true">
                    <div
                      class="vertical-without-time vertical-timeline vertical-timeline&#45;&#45;animate vertical-timeline&#45;&#45;one-column">
                      <div class="vertical-timeline-item vertical-timeline-element" *ngFor="let notification of obtenerNotificacionPorTipo('SOLICITUD_DEVOLUCION')">
                        <div><span class="vertical-timeline-element-icon bounce-in"><i
                              class="badge badge-dot badge-dot-xl badge-success"></i></span>
                          <div class="vertical-timeline-element-content bounce-vin">
                            <h4 class="timeline-title">{{notification.title}}</h4>
                            <p>{{notification.descripcion}}</p>
                            <div *ngIf = "notification.plataforma === 'BK'" class="badge badge-pill badge-primary">BIKERACE</div>
                              <div *ngIf = "notification.plataforma === 'RT'" class="badge badge-pill badge-primary">RIDERTICKET</div>
                            <p>{{notification.fecha | date : 'dd-MM-yyyy HH:mm' : 'ISO'}}</p>
                            <span
                              class="vertical-timeline-element-date"></span>

                              <button (click)="clickTarea(notification)" type="button" class="btn-shadow d-inline-flex align-items-center btn btn-primary btn-transition btn-shadow btn-hover-shine">
                                <em class="fa fa-mouse-pointer" title="star" style="margin-right: 10px"></em>
                                Ir a solicitud
                              </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
              <div class="no-results" *ngIf="obtenerNotificacionPorTipo('SOLICITUD_DEVOLUCION').length === 0">
                <div  class="swal2-icon swal2-success swal2-animate-success-icon">
                  <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                  <span class="swal2-success-line-tip"></span>
                  <span class="swal2-success-line-long"></span>
                  <div class="swal2-success-ring"></div>
                  <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                  <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                </div>
                <!-- <div *ngIf="user.valitedUser" class="results-subtitle">Activado!</div>
                <div *ngIf="user.valitedUser" class="results-title">Tu cuenta está validada!</div> -->

                <div  class="results-subtitle">Perfecto!</div>
                <div  class="results-title">No tienes tareas pendientes por realizar!</div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><span>Rev. transferencia</span></ng-template>
            <ng-template ngbTabContent>
              <div class="scroll-gradient" *ngIf="obtenerNotificacionPorTipo('SOLICITUD_REVISION_TRANSFERENCIA').length > 0">
                <div class="scroll-area-sm shadow-overflow">
                  <perfect-scrollbar [autoPropagation]="true">
                    <div
                      class="vertical-without-time vertical-timeline vertical-timeline&#45;&#45;animate vertical-timeline&#45;&#45;one-column">
                      <div class="vertical-timeline-item vertical-timeline-element" *ngFor="let notification of obtenerNotificacionPorTipo('SOLICITUD_REVISION_TRANSFERENCIA')">
                        <div><span class="vertical-timeline-element-icon bounce-in"><i
                              class="badge badge-dot badge-dot-xl badge-success"></i></span>
                          <div class="vertical-timeline-element-content bounce-vin">
                            <h4 class="timeline-title">{{notification.title}}</h4>
                            <p>{{notification.descripcion}}</p>
                            <div *ngIf = "notification.plataforma === 'BK'" class="badge badge-pill badge-primary">BIKERACE</div>
                              <div *ngIf = "notification.plataforma === 'RT'" class="badge badge-pill badge-primary">RIDERTICKET</div>
                            <p>{{notification.fecha | date : 'dd-MM-yyyy HH:mm' : 'ISO'}}</p>
                            <span
                              class="vertical-timeline-element-date"></span>
                              <button (click)="clickTarea(notification)" type="button" class="btn-shadow d-inline-flex align-items-center btn btn-primary btn-transition btn-shadow btn-hover-shine">
                                <em class="fa fa-mouse-pointer" title="star" style="margin-right: 10px"></em>
                                Ir a transferencia
                              </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
              <div class="no-results" *ngIf="obtenerNotificacionPorTipo('SOLICITUD_REVISION_TRANSFERENCIA').length === 0">
                <div  class="swal2-icon swal2-success swal2-animate-success-icon">
                  <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                  <span class="swal2-success-line-tip"></span>
                  <span class="swal2-success-line-long"></span>
                  <div class="swal2-success-ring"></div>
                  <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                  <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                </div>

                <div  class="results-subtitle">Perfecto!</div>
                <div  class="results-title">No tienes tareas pendientes por realizar!</div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
      <div class="nav-justified">
        <ul class="nav flex-column">
          <li class="nav-item-divider nav-item mt-0"></li>
          <li class="nav-item-btn text-center nav-item">
            <a [routerLink]="['/dashboard']" >
              <button class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm">Ver más</button>
            </a>

          </li>
        </ul>
      </div>
    </div>
  </div>

</div>

<app-widget-modal-container></app-widget-modal-container>
