import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../_shared/storage';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private localStorageAs: LocalStorageService,
  ) { }


  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let stateGlobal = null;

    this.localStorageAs.watch('dataUser').subscribe(dataUser => {
      if (dataUser && JSON.parse(dataUser).token) {
        stateGlobal = true;
      } else {
        stateGlobal = false;
      }
    });


    if (stateGlobal) {
      return true;
    }

    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
