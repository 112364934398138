import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { NgxLoadingModule } from 'ngx-loading';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { WidgetMessageConfirmationModule } from './widget-message-confirmation/widget-message-confirmation.module';
import { WidgetModalContainerModule } from './widget-modal-container/widget-modal-container.module';
import { WidgetTableModule } from './widget-table/widget-table.module';
import { WidgetToastModule } from './widget-toast/widget-toast.module';
import { WidgetUploadDocumentModule } from './widget-upload-document/widget-upload-document.module';
import { WidgetUploadFileModalModule } from './widget-upload-file-modal/widget-upload-file-modal.module';

// Components
import { RouterModule } from "@angular/router";
import { PageTitleComponent } from './page-title/page-title.component';
import { WidgetMessageComponent } from './widget-message/widget-message.component';
import { WidgetMessageModule } from './widget-message/widget-message.module';
import { PreviewModule } from './widget-modal-preview-email/preview.module';

@NgModule({
  declarations: [
    PageTitleComponent
  ],
  imports: [
    CommonModule,
    RoundProgressModule,
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetToastModule,
    JwBootstrapSwitchNg2Module,
    NgxLoadingModule,
    WidgetTableModule,
    WidgetUploadFileModalModule,
    WidgetModalContainerModule,
    WidgetMessageConfirmationModule,
    WidgetUploadDocumentModule,
    WidgetMessageModule,
    PreviewModule,
    RouterModule
  ],
  exports: [
    PageTitleComponent,
    RoundProgressModule,
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetToastModule,
    JwBootstrapSwitchNg2Module,
    NgxLoadingModule,
    WidgetTableModule,
    WidgetUploadFileModalModule,
    WidgetModalContainerModule,
    WidgetMessageConfirmationModule,
    WidgetUploadDocumentModule,
    WidgetMessageComponent,
  ]
})
export class ComponentsModule { }
