import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { WidgetModalContainerComponent } from './widget-modal-container.component';
import { WidgetModalWrappingComponent } from './widget-modal-wrapping/widget-modal-wrapping.component';

@NgModule({
  declarations: [WidgetModalContainerComponent, WidgetModalWrappingComponent],
  exports: [WidgetModalContainerComponent, WidgetModalWrappingComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
})
export class WidgetModalContainerModule { }
