import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class SolicitudCambioCategoriaRiderService {

  // Observable refresh list data categorias
  flagRefresh = new BehaviorSubject<any>(null);
  observableData$ = this.flagRefresh.asObservable();

  constructor(private http: HttpClient) { }

  // actualizamos flag
  nextData(flag: boolean) {
    this.flagRefresh.next(flag);
  }

  obtenerTodasSolicitudesPorEstadoYEvento(estado:string, eventoId: number) {
    return this.http.get<any>(`${environment.api}/solicitud-cambio-categoria-rider/estado/${estado}/evento/${eventoId}`);
  }

  obtenerTodasSolicitudesPorEstadoYLimite(estado:string, limite: number) {
    return this.http.get<any>(`${environment.api}/solicitud-cambio-categoria-rider/estado/${estado}/limite/${limite}`);
  }

  actualizarCambioCategoria(solicitudCambioCategoriaId: number, form: any) {
    return this.http.patch(`${environment.api}/solicitud-cambio-categoria-rider/${solicitudCambioCategoriaId}`, form);
  }

  obtenerSolicitudPorId(id: number) {
    return this.http.get<any>(`${environment.api}/solicitud-cambio-categoria-rider/${id}`);
  }

}
