import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../../theme-options';

// Services
import { AuthenticationService } from 'src/app/_services';
import { LocalStorageService } from 'src/app/_shared/storage';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  constructor(
    public globals: ThemeOptions,
    public authService: AuthenticationService,
    private localStorageAs: LocalStorageService,

  ) {
  }

  // declaraciones
  public user = null;
  public userDataComplete = null;


  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  ngOnInit() {

    this.localStorageAs.watch('dataUserComplete').subscribe(dataUserComplete => {
      if (dataUserComplete) {
        this.user = JSON.parse(this.localStorageAs.get('userDataCompleteInSession'));
        this.userDataComplete = JSON.parse(this.localStorageAs.get('dataUserComplete'));
      }
    });
  }

  logout() {
    this.authService.logout();
  }

}
