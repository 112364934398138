import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../_shared/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthSoporteGuard implements CanActivate {

  constructor(private route: Router,
    private localStorageAs: LocalStorageService,
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      this.localStorageAs.watch('dataUser').subscribe(dataUser => {
        if (dataUser !== null){
          if (JSON.parse(dataUser).role === 'Administrador' || JSON.parse(dataUser).role === 'Organizador') {
            obs.next(true);
          } else {
            this.route.navigate(['/auth']);
          }
        }else{
          this.route.navigate(['/auth']);

        }
      });
    });

  }
}
