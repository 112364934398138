<div [class]="'app-sidebar sidebar-shadow '" (mouseover)="sidebarHoverMouseIn()"
  (mouseout)="sidebarHoverMouseOut()">
  <div class="app-header__logo">
    <!-- class="logo-src" -->
    <div *ngIf="globals.toggleSidebar">
      <img src="./assets/images/logo.png" style="width: 60px; margin-left: -13px" alt="">
    </div>
    <div *ngIf="!globals.toggleSidebar">
      <img src="./assets/images/logoazul.png" width="150px"  alt="">
    </div>
    <div class="header__pane ml-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{ 'is-active': globals.toggleSidebar }" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content" style="overflow-y: auto;">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [scrollIndicators]="true"
        style="max-width: 600px;">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" #acc="ngbAccordion" activeIds="{{ activeId }}">
              <ng-container *ngIf="filterMenu('GENERAL').length > 0">
                <ngb-panel >
                  <ng-template ngbPanelHeader>
                    <div class="vsm-header">GENERAL</div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel *ngFor="let menu of filterMenu('GENERAL')"  [id]="menu.titulo+'_GENERAL'">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <i class="vsm-icon" [ngClass]="menu.icono"></i>
                      <span class="vsm-title">{{menu.titulo}}</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let subMenu of menu.subMenus">
                          <a [routerLink]="[subMenu.url]" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">{{subMenu.titulo}}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ng-container>
              
              <ng-container *ngIf="filterMenu('BIKERACE').length > 0">
                <ngb-panel>
                  <ng-template ngbPanelHeader>
                    <div class="vsm-header">BIKERACE</div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel *ngFor="let menu of filterMenu('BIKERACE')" [id]="menu.titulo+'_BIKERACE'">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <i class="vsm-icon" [ngClass]="menu.icono"></i>
                      <span class="vsm-title">{{menu.titulo}}</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let subMenu of menu.subMenus">
                          <a [routerLink]="[subMenu.url]" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">{{subMenu.titulo}}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ng-container>

              <ng-container *ngIf="filterMenu('RIDERTICKET').length > 0">
                <ngb-panel>
                  <ng-template ngbPanelHeader>
                    <div class="vsm-header">RIDERTICKET</div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel *ngFor="let menu of filterMenu('RIDERTICKET')" [id]="menu.titulo+'_RIDERTICKET'">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <i class="vsm-icon" [ngClass]="menu.icono"></i>
                      <span class="vsm-title">{{menu.titulo}}</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let subMenu of menu.subMenus">
                          <a [routerLink]="[subMenu.url]" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">{{subMenu.titulo}}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ng-container>

            </ngb-accordion>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
